.wrapper {
    min-height: 400px;
    background-image: url('../../assets/images/photo_2024-09-12_20-43-35.webp');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: -340px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1200px) {
        background-position: 0;
    }
    @media screen and (max-width: 600px) {
        min-height: fit-content;
        padding: 10px;
    }
}
.textBlock {
    max-width: fit-content;
    width: 100%;
    background-color: #ffffff8f;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    padding: 15px 30px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-attachment: scroll;
    @media screen and (max-width: 480px) {
        font-size: 16px;
    }
}
.dateLoc {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.span {
    display: block;
    text-align: left;
    font-weight: 400;
}
