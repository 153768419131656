.wrapper {
    display: flex;
    flex-direction: column;
    gap: 48px;
}
.listWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 60%;
    line-height: 26px;
    font-size: 16px;
    strong {
        font-weight: 700;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}
.listItemsWrapper {
    display: flex;
    gap: 16px;
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }
}
.imageBlock {
    width: 40%;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}
.listItem {
    display: flex;
    gap: 8px;
    &::before {
        content: '';
        display: block;
        min-width: 30px;
        width: 30px;
        height: 30px;
        background-image: url('../../assets/images/success-green-check-mark-icon.svg');
    }
}
