.wrapper {
    height: 80px;
    position: relative;
    width: 100%;
    max-width: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #fff;
}
.left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -200px;
    opacity: 0;
    width: 50%;
    z-index: -1;
    height: 100%;
    background-color: rgba(127, 160, 70);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    z-index: -1;
    height: 100%;
    background-color: rgba(127, 160, 70);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
