.wrapper {
    display: flex;
    flex-direction: column;
    gap: 48px;
}
.daysList {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.daysListItem {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.dayList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    line-height: 26px;
}
.additional {
    p {
        text-align: center;
        color: #0000008c;
    }
}
