.wrapper {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 36px;
}
.textBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
}
.text {
    position: relative;
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    width: 100%;
    opacity: 0;
    @media screen and (max-width: 1060px) {
        line-height: 26px;
        font-size: 16px;
    }
    @media screen and (max-width: 700px) {
        line-height: 20px;
        font-size: 16px;
    }
}
.image {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 100%;
    }
}
