.wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    h4 {
        font-size: 30px;
        position: relative;
        z-index: 2;
    }
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #ffffffbe;
        z-index: 0;
    }
}
.includeWrapper {
    box-shadow: 2px 2px 10px 0px #35cf00;
    animation: boxAnimate infinite 1s alternate;
    background-image: url('../../assets/images/ocean1.webp');
    background-size: cover;
    h4 {
        color: #34cf00;
        font-weight: 700;
        text-shadow: 1px 1px 0px #000000;
    }
}
@keyframes boxAnimate {
    0% {
        box-shadow: 0px 0px 10px 0px #34cf004f;
    }
    100% {
        box-shadow: 0px 0px 10px 0px #34cf00;
    }
}
.excludeWrapper {
    box-shadow: 2px 2px 10px 0px #e04111;
    animation: boxAnimateExclude infinite 1s alternate;
    background-image: url('../../assets/images/ocean2.webp');
    background-size: cover;

    h4 {
        color: #e04111;
        font-weight: 700;
        text-shadow: 1px 1px 0px #000000;
    }
}
@keyframes boxAnimateExclude {
    0% {
        box-shadow: 2px 2px 10px 0px #e0411169;
    }
    100% {
        box-shadow: 2px 2px 10px 0px #e04111;
    }
}
.includeListItems,
.excludeListItems {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    z-index: 2;
}

.includeListItem {
    display: flex;
    align-items: center;
    gap: 8px;
    &::before {
        content: '';
        display: block;
        min-width: 25px;
        width: 25px;
        height: 25px;
        background-image: url('../../assets/images/success-green-check-mark-icon.svg');
    }
}
.excludeListItem {
    display: flex;
    align-items: center;
    gap: 8px;
    &::before {
        content: '';
        display: block;
        min-width: 25px;

        width: 25px;
        height: 25px;
        background-image: url('../../assets/images/icons/red-x-line-icon.svg');
        background-size: 100%;
    }
}
